export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 420,
    CanvasHeight: 220,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#757575',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 80,
    CanvasChartY: 30,
    CanvasChartWidth: 300,
    CanvasChartAreaAllowed: 'Y',
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#bebebe',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 10,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- TITLE
  Title: {
    TitleMainDisplay: 'Y',
    TitleMainText: 'Spool Installation',
    TitleMainX: 80,
    TitleMainY: 20,
    TitleMainFont: 'roboto',
    TitleMainStyle: 'regular',
    TitleMainSize: 14,
    TitleMainColor: '#44A9DF',
    TitleMainAlign: 'start',

    TitleSubDisplay: 'N',
    TitleSubText: 'MD2 Project',
    TitleSubX: 350,
    TitleSubY: 70,
    TitleSubFont: 'roboto',
    TitleSubStyle: 'regular',
    TitleSubSize: 12,
    TitleSubColor: '#BDBCBC',
    TitleSubAlign: 'middle',

    TitleExtraDisplay: 'N',
    TitleExtraText: 'Discipline',
    TitleExtraX: 350,
    TitleExtraY: 430,
    TitleExtraFont: 'roboto',
    TitleExtraStyle: 'regular',
    TitleExtraSize: 14,
    TitleExtraColor: 'gray',
    TitleExtraAlign: 'middle',

    TitleSqlDisplay: 'N',
    TitleSqlText: 'SQL Query...',
    TitleSqlQuery: 'SQL Query...',
    TitleSqlX: 400,
    TitleSqlY: 90,
    TitleSqlFont: 'roboto',
    TitleSqlStyle: 'regular',
    TitleSqlSize: 11,
    TitleSqlColor: 'pink',
    TitleSqlAlign: 'middle',

    TitleLineDisplay: 'N',
    TitleLineX: 30,
    TitleLineY: 30,
    TitleLineLength: 100,
    TitleLineWeight: 1,
    TitleLineColor: 'black'
  },

  // ----------------------------------------------- BAR
  Bar: {
    BarAutoSize: 'N',
    BarThickness: 15,
    BarDistance: 10,
    BarBackColor: '#F5F5F5',
    BarColorType: 'LinearA4',
    BarColorSet: ['Gold', 'Orange', 'Pink', 'Gray', 'SkyBlue', 'LightBlue', 'YellowGreen', 'Red', 'Thistle', 'Purple', 'Indigo', 'LightBlue', 'SkyBlue', 'SteelBlue', 'YellowGreen', 'DarkGreen'],

    BarSeriesDisplay: 'Y',
    BarSeriesSpace: 5,
    BarSeriesFont: 'roboto',
    BarSeriesStyle: 'regular',
    BarSeriesSize: 11,
    BarSeriesAutoColor: 'N',
    BarSeriesColor: '#757575',

  },

  // ----------------------------------------------- Value
  Value: {
    ValueActualFont: 'roboto',
    ValueActualStyle: 'regular',
    ValueActualSize: 10,
    ValueActualColor1: '#ffffff',
    ValueActualColor2: '#333333',
    ValueActualRound: 1,

    ValueUnitConvert: 'Y',

    ValueOutstandDisplay: 'Y',
    ValueOutstandFont: 'roboto',
    ValueOutstandStyle: 'regular',
    ValueOutstandSize: 9,
    ValueOutstandColor: '#FF9E80',

    ValueTotalDisplay: 'Y',
    ValueTotalFont: 'roboto',
    ValueTotalStyle: 'regular',
    ValueTotalSize: 10,
    ValueTotalColor: '#757575',
  },

  // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 100,
    NoteY: 429,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },

  DataItems: [
    { title: 'Thistle', actual: 350, total: 700 },
    { title: 'Purple', actual: 40 , total: 954 },
    { title: 'Indigo.', actual: 410, total: 1248 },
    { title: 'LightBlue', actual: 150, total: 1047 },
    { title: 'SkyBlue.', actual: 970, total: 987 },
    { title: 'SteelBlue.', actual: 598, total: 745 },
    { title: 'YellowGreen', actual: 170, total: 1384 }
  ]
}
