<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Compare</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Chart Area
        <a class="close" v-on:click="collapsed['area'] = !collapsed['area']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['area']">
        <div class="type_common_input">
          <label>Width</label>
          <input v-model="CompareAreaWidth" type="text" />
        </div>
        <div class="type_common_input">
          <label>Height</label>
          <input v-model="CompareAreaHeight" type="text" />
        </div>
        <div class="type_common_input">
          <label>
            Space
            <small class="type_ref03">(Top)</small>
          </label>
          <input v-model="CompareAreaSpace" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Group Title
        <a class="close" v-on:click="collapsed['group'] = !collapsed['group']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['group']">
        <div>
          <span class="label">Left Title</span>
          <div class="type_inner">
            <div class="type_common_input">
              <input v-model="CompareTitleLeft" type="text" placeholder="Chart Name" class="type100" />
            </div>
          </div>
        </div>
        <div>
          <span class="label">Right Title</span>
          <div class="type_inner">
            <div class="type_common_input">
              <input v-model="CompareTitleRight" type="text" placeholder="Chart Name" class="type100" />
            </div>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="CompareTitleFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="CompareTitleStyle" dir="rtl" class="type70">
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="CompareTitleSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="CompareTitleColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-compare',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    bar3d_general: null,
    collapsed: {
      area: false,
      group: false,
    },
    axesLevel: [
      { text: "Yes", val: "Y" },
      { text: "No", val: "N" }
    ],
    axesLevelFont: [
      { text: "Roboto", val: "roboto" },
      { text: "Arial", val: "arial" }
    ],
    axesLevelStyle: [
      { text: "Regular", val: "regular" },
      { text: "Thin", val: "thin" },
      { text: "Bold italic", val: "boldA" },
      { text: "Bold", val: "boldB" },
      { text: "Condensed", val: "condensedA" },
      { text: "Light italic", val: "light" },
      { text: "Medium italic", val: "mediumA" },
      { text: "Black", val: "black" },
      { text: "Medium", val: "mediumB" },
      { text: "Condensed Light", val: "condensedB" },
    ],
    axesLevelUnit: [
      { text: "Size", class: "", val: "size" },
      { text: "Format", class: "type22", val: "format" }
    ],
    axesGridsDisplay: [
      { text: "Yes", val: "Y" },
      { text: "No", val: "N" }
    ],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['compare']),

    CompareAreaWidth: {
      get() { return this.compare.CompareAreaWidth || 0 },
      set(val) { this.setChartItem({ CompareAreaWidth: parseFloat(val || 0) })}
    },
    CompareAreaHeight: {
      get() { return this.compare.CompareAreaHeight || 0 },
      set(val) { this.setChartItem({ CompareAreaHeight: parseFloat(val || 0) })}
    },
    CompareAreaSpace: {
      get() { return this.compare.CompareAreaSpace || 0 },
      set(val) { this.setChartItem({ CompareAreaSpace: parseFloat(val || 0) })}
    },
    CompareTitleLeft: {
      get() { return this.compare.CompareTitleLeft },
      set(val) { this.setChartItem({ CompareTitleLeft: val })}
    },
    CompareTitleRight: {
      get() { return this.compare.CompareTitleRight },
      set(val) { this.setChartItem({ CompareTitleRight: val })}
    },
    CompareTitleFont: {
      get() { return this.compare.CompareTitleFont },
      set(val) { this.setChartItem({ CompareTitleFont: val })}
    },
    CompareTitleStyle: {
      get() { return this.compare.CompareTitleStyle },
      set(val) { this.setChartItem({ CompareTitleStyle: val })}
    },
    CompareTitleSize: {
      get() { return this.compare.CompareTitleSize || 0 },
      set(val) { this.setChartItem({ CompareTitleSize: parseFloat(val || 0) })}
    },
    CompareTitleColor: {
      get() { return this.compare.CompareTitleColor },
      set(val) { this.setChartItem({ CompareTitleColor: val })}
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>

<style>
</style>
