<template>
  <div>
    <v-dialog 
      fullscreen    
      hide-overlay
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>Format Progress Bar Chart <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>

        <v-card-text :class="tabClass()">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                    <div
                      data-progress-quickbtn="normal"
                      title="normal"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_NORMAL ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_NORMAL)"
                    ></div>
                  </div>
                  <div>
                    <div
                      data-progress-quickbtn="emboss"
                      title="emboss"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_EMBOSSED ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_EMBOSSED)"
                    ></div>
                  </div>
                  <!-- <div>
                    <div
                      data-progress-quickbtn="comparison"
                      @click="onChartType(__C_.TYPE_CODE_COMPARATIVE)"
                      title="comparison"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_COMPARATIVE ? 'selected': ''"
                    ></div>
                </div> -->
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                  >Save
                </j-button>
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('saveas')"
                  >SaveAs
                </j-button>
                <j-button v-if="modeMod" 
                  class="type01 delete  type_full" 
                  @click="onAction('delete')"
                  >Delete
                </j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="title" title="title"></v-tab>
                  <v-tab data-type="bar" title="bar"></v-tab>
                  <v-tab data-type="value" title="value"></v-tab>
                  <v-tab data-type="note" title="note"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-title @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-bar :background="background" :chart-name="'progress'" :value="valuetab" :units="units" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-value />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-note />
                  </v-tab-item>
                </v-tabs>

                <j-chart-bar-progress
                  class="canvasAreaLine"
                  ref="chart"
                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"
                  :ChartType="chartItem.ChartType"
                  :Database="database"
                  :Canvas="canvas"
                  :Title="title"
                  :Bar="bar"
                  :Value="values"
                  :Compare="compare"
                  :Note="note"
                  :DataItems="chartData"
                  @moved="onMoved"
                  @to-xml-string="onXmlString"
                />

                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td>{{ props.item.name }}</td>
                          <td style="text-align: center;">{{ props.item.actual }}</td>
                          <td style="text-align: center;">{{ props.item.total }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>

                <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                </div>


              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartBarProgressDefault'
import ChartModalMixin from "@/mixins/chart.modal.common"
import JChartTabProps from './SysenvChartlibBarProgress'

export default {
  name: 'sysenv-chartlib-bar-progress-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    ...JChartTabProps
  },
  data: () => ({
    compareOnoff: false,
    background: false,
    valuetab: false,
    units: false,
    previewHeaders: [
      { type: 'text', text: 'name', value: 'name', sortable: false },
      { type: 'text', text: 'actual', value: 'actual', sortable: false },
      { type: 'text', text: 'total', value: 'total', sortable: false },
    ],
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },
    previewItems() { return this.chartData },
  },
  watch: {
    // for the default chart-type setting
    chartData: {
      handler(val) {
        if (!val || val.length === 0) return
        // if the chart-type is not selected yet, 
        // set the default chart-type with __C.CHART.TYPE_CODE_EMBOSSED.
        if (this.modeNew && !this.chartItem['ChartType']) this.onChartType(__C.CHART.TYPE_CODE_EMBOSSED)
      },
      deep: true
    },
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      // every time came into the modal, either the mode new or edit,
      // whole the store's data should be initialized.
      this.setEmpty()
      this.$refs.chart.clear()

      if (this.modeNew) {
        this.setChartItem({
          ChartDb: 'Progress',
          CatCode: __C.CHART.CAT_CODE_BAR_PROGRESS,

          ..._ChartDataDefault.Database,
          ..._ChartDataDefault.Canvas,
          ..._ChartDataDefault.Title,
          ..._ChartDataDefault.Bar,
          ..._ChartDataDefault.Value,
          ..._ChartDataDefault.Compare,
          ..._ChartDataDefault.Note,
          
          QApplied: 'J',
          JsonString: JSON.stringify(_ChartDataDefault.DataItems),
        })

        this.setChartData(_ChartDataDefault.DataItems)
        this.onChartType(__C.CHART.TYPE_CODE_EMBOSSED)
        this.$refs.general.setData(this.formMode)

      } else {
        this.getChart()
      }
    }
  },
  methods: {
    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    onChartType(typeName) {
      this.setChartItem({ ChartType: typeName })
      this.onDisplayElements(typeName)
    },
    onDisplayElements(typeName) {
      if (__C.CHART.TYPE_CODE_COMPARATIVE == typeName) {
        this.compareOnoff = true

      } else {
        this.compareOnoff = false
      }
    },
    tabClass() {
      return {
        'modal__formControl': 1,
        'type2': 1,
        'dark_theme' : 1,
        'properties7': 1
      }
    },
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartBarProgress(code || this.keyValue, res => {
          this.setChartItem(res)
          this.onChartType(this.chartItem.ChartType)
          this.setChartData(this.chartItem.QApplied == 'J' ? JSON.parse(this.chartItem.JsonString) : this.chartItem.QResultSummary)

          this.$refs.general.setData(this.formMode)

          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartBarProgress(reqItem, callback)
    },
    sasChart(reqItem, callback) {
      // console.log('SysenvChartlibBarProgress putChart',reqItem)
      this.chartLibraryService.sasChartBarProgress(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartBarProgress(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartBarProgress(chartNo, callback)
    },
  }
}
</script>
