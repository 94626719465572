<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Value</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Title
        <a class="close" v-on:click="collapsed['actual'] = !collapsed['actual']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['actual']">
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="ValueActualFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="ValueActualStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="ValueActualSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="ValueActualColor1" title="1st Color"></j-color-picker>
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="ValueActualColor2" title="Changed Color (2nd)"></j-color-picker>
        </div>
        <div class="type_common_input">
          <label>
            Round
            <small class="type_ref03">(Number Only)</small>
          </label>
          <input v-model="ValueActualRound" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <div>
          Unints
          <small class="type_gray">(%)</small>
        </div>
        <a class="close" v-on:click="collapsed['units'] = !collapsed['units']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['units']">
        <div class="type_common_input">
          <span class="label">Convert</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="ValueUnitConvert"
                type="radio"
                name="ValueUnitConvert"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <!-- <div class="type_common_input">
          <label>Size</label>
          <input v-model="ValueUnitSize" type="number" />
        </div> -->
        <!-- <div class="type_common_input">
          <j-color-picker v-model="ValueUnitColor1" title="1st Color"></j-color-picker>
        </div> -->
        <!-- <div class="type_common_input">
          <j-color-picker v-model="ValueUnitColor2" title="Changed Color (2nd)"></j-color-picker>
        </div> -->
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Outstanding
        <a
          class="close"
          v-on:click="collapsed['ountstanding'] = !collapsed['ountstanding']"
        ></a>
      </div>
      <v-flex class="control" v-show="collapsed['ountstanding']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="ValueOutstandDisplay"
                type="radio"
                name="ValueOutstandDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="ValueOutstandFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="ValueOutstandStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="ValueOutstandSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="ValueOutstandColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div v-if="chartTypeComp" class="chart_properties_contents">
      <div class="header">
        Total
        <a class="close" v-on:click="collapsed['total'] = !collapsed['total']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['total']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="ValueTotalDisplay"
                type="radio"
                name="ValueTotalDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="ValueTotalFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="ValueTotalStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="ValueTotalSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="ValueTotalColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-value',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    bar3d_general: null,
    collapsed: {
      actual: false,
      units: false,
      ountstanding: false,
      total: false,
    },
    axesLevel: [
      { text: "Yes", val: "Y" },
      { text: "No", val: "N" }
    ],
    axesLevelFont: [
      { text: "Roboto", val: "roboto" },
      { text: "Arial", val: "arial" }
    ],
    axesLevelStyle: [
      { text: "Regular", val: "regular" },
      { text: "Thin", val: "thin" },
      { text: "Bold italic", val: "boldA" },
      { text: "Bold", val: "boldB" },
      { text: "Condensed", val: "condensedA" },
      { text: "Light italic", val: "light" },
      { text: "Medium italic", val: "mediumA" },
      { text: "Black", val: "black" },
      { text: "Medium", val: "mediumB" },
      { text: "Condensed Light", val: "condensedB" },
    ],
    axesLevelUnit: [
      { text: "Size", class: "", val: "size" },
      { text: "Format", class: "type22", val: "format" }
    ],
    axesGridsDisplay: [
      { text: "Yes", val: "Y" },
      { text: "No", val: "N" }
    ],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    chartTypeComp() { return this.chartItem.ChartType != __C.CHART.TYPE_CODE_COMPARATIVE },

    ValueActualFont: {
      get() { return this.chartItem.ValueActualFont },
      set(val) { this.setChartItem({ ValueActualFont: val }) }
    },
    ValueActualStyle: {
      get() { return this.chartItem.ValueActualStyle },
      set(val) { this.setChartItem({ ValueActualStyle: val }) }
    },
    ValueActualSize: {
      get() { return this.chartItem.ValueActualSize || 0 },
      set(val) { this.setChartItem({ ValueActualSize: parseFloat(val || 0) }) }
    },
    ValueActualColor1: {
      get() { return this.chartItem.ValueActualColor1 },
      set(val) { this.setChartItem({ ValueActualColor1: val }) }
    },
    ValueActualColor2: {
      get() { return this.chartItem.ValueActualColor2 },
      set(val) { this.setChartItem({ ValueActualColor2: val }) }
    },
    ValueActualRound: {
      get() { return this.chartItem.ValueActualRound || 0 },
      set(val) { this.setChartItem({ ValueActualRound: parseFloat(val || 0) }) }
    },
    ValueUnitConvert: {
      get() { return this.chartItem.ValueUnitConvert },
      set(val) { this.setChartItem({ ValueUnitConvert: val }) }
    },
    ValueUnitSize: {
      get() { return this.chartItem.ValueUnitSize || 0 },
      set(val) { this.setChartItem({ ValueUnitSize: parseFloat(val || 0) }) }
    },
    ValueUnitColor1: {
      get() { return this.chartItem.ValueUnitColor1 },
      set(val) { this.setChartItem({ ValueUnitColor1: val }) }
    },
    ValueUnitColor2: {
      get() { return this.chartItem.ValueUnitColor2 },
      set(val) { this.setChartItem({ ValueUnitColor2: val }) }
    },
    ValueOutstandDisplay: {
      get() { return this.chartItem.ValueOutstandDisplay },
      set(val) { this.setChartItem({ ValueOutstandDisplay: val }) }
    },
    ValueOutstandFont: {
      get() { return this.chartItem.ValueOutstandFont },
      set(val) { this.setChartItem({ ValueOutstandFont: val }) }
    },
    ValueOutstandStyle: {
      get() { return this.chartItem.ValueOutstandStyle },
      set(val) { this.setChartItem({ ValueOutstandStyle: val }) }
    },
    ValueOutstandSize: {
      get() { return this.chartItem.ValueOutstandSize || 0 },
      set(val) { this.setChartItem({ ValueOutstandSize: parseFloat(val || 0) }) }
    },
    ValueOutstandColor: {
      get() { return this.chartItem.ValueOutstandColor },
      set(val) { this.setChartItem({ ValueOutstandColor: val }) }
    },
    ValueTotalDisplay: {
      get() { return this.chartItem.ValueTotalDisplay },
      set(val) { this.setChartItem({ ValueTotalDisplay: val }) }
    },
    ValueTotalFont: {
      get() { return this.chartItem.ValueTotalFont },
      set(val) { this.setChartItem({ ValueTotalFont: val }) }
    },
    ValueTotalStyle: {
      get() { return this.chartItem.ValueTotalStyle },
      set(val) { this.setChartItem({ ValueTotalStyle: val }) }
    },
    ValueTotalSize: {
      get() { return this.chartItem.ValueTotalSize || 0 },
      set(val) { this.setChartItem({ ValueTotalSize: parseFloat(val || 0) }) }
    },
    ValueTotalColor: {
      get() { return this.chartItem.ValueTotalColor },
      set(val) { this.setChartItem({ ValueTotalColor: val }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>

<style>
</style>
