import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabBar from '@/components/chart/tabs/JChartTabBar'
// import JChartTabBar from './JChartTabBar'
import JChartTabValue from './JChartTabValue'
import JChartTabCompare from './JChartTabCompare'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTitle,
  JChartTabBar,
  JChartTabValue,
  JChartTabCompare,
  JChartTabNote
}
